import React from 'react';
import mon1 from '../../images/1.png';
import mon2 from '../../images/2.png';
import mon3 from '../../images/3.png';
import mon4 from '../../images/4.png';
import mon5 from '../../images/5.png';
import mon6 from '../../images/6.png';
import mon7 from '../../images/7.png';
import mon8 from '../../images/8.png';

import hat1 from '../../images/hat1.svg';
import hat2 from '../../images/hat2.svg';
import hat3 from '../../images/hat3.svg';
import hat4 from '../../images/hat4.svg';
import hat5 from '../../images/hat5.svg';
import hat6 from '../../images/hat6.svg';
import hat7 from '../../images/hat7.svg';
import hat8 from '../../images/hat8.svg';

import twitterIcon from '../../images/twitter-icon.svg';

import { useTranslation } from 'gatsby-plugin-react-i18next';

const Team: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <section id="team" className="w-full flex-col">
      <div className="flex justify-center">
        <div className=" w-5/6 md:w-4/6">
          <h2 className="text-3xl text-yellow-300 font-medium mt-16 mb-12 font-super uppercase">
            {t('Team.heading', 'Party Planners')}
          </h2>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="w-5/6 md:w-3/5">
          {/* <h1 className="text-3xl text-yellow-300 font-medium mt-20 mb-12 font-super uppercase">
            {t('Team.heading', 'Party Planners')}
          </h1> */}
          <div className="flex flex-col items-center md:space-x-8 md:flex-row md:justify-between md:justify-between md:items-baseline text-xl text-center">
            <div className="flex flex-row space-x-8 mb-8">
              <a
                href="https://twitter.com/BitByTheByte"
                target="_blank"
                className="md:w-1/2 mt-4 hover:animate-bounceOnce cursor-pointer"
              >
                <div className="relative">
                  <img className="absolute w-20 team-hat" src={hat1} alt="" />
                  <img className="border-2 border-white rounded-xl" src={mon1} alt="" />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <img src={twitterIcon} className="w-5 mr-2" />
                  <span className="block font-heading text-2xl leading-none">BIT</span>
                </div>
                <span className="text-md lg:text-lg leading-none">
                  {' '}
                  {t('Team.member1.title', 'Dev Master')}
                </span>
              </a>
              {/* <a
                href="https://twitter.com/MonstaPartyNFTs"
                target="_blank"
                className="md:w-1/2 mt-4 hover:animate-bounceOnce cursor-pointer"
              >
                <div className="relative">
                  <img className="absolute w-20 team-hat" src={hat2} alt="" />
                  <img className="border-2 border-white rounded-xl" src={mon2} alt="" />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <img src={twitterIcon} className="w-5 mr-2" />
                  <span className="block font-heading text-2xl leading-none">BYRAN</span>
                </div>
                <span className="text-md lg:text-lg leading-none">
                  {' '}
                  {t('Team.member2.title', 'Dogsbody')}
                </span>
              </a> */}
            {/* </div>
            <div className="flex flex-row space-x-8 mb-8"> */}
              <a
                href="https://twitter.com/whaleorian"
                target="_blank"
                className="md:w-1/2 mt-4 hover:animate-bounceOnce cursor-pointer"
              >
                <div className="relative">
                  <img className="absolute w-20 team-hat" src={hat3} alt="" />
                  <img className="border-2 border-white rounded-xl" src={mon3} alt="" />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <img src={twitterIcon} className="w-5 mr-2" />
                  <span className="block font-heading text-2xl leading-none text-center">
                    WHALE
                  </span>
                </div>
                <span className="text-md lg:text-lg leading-none">
                  {' '}
                  {t('Team.member3.title', 'Number Juggler')}
                </span>
              </a>
              <a
                href="https://twitter.com/MonstaPartyNFTs"
                target="_blank"
                className="md:w-1/2 mt-4 hover:animate-bounceOnce cursor-pointer"
              >
                <div className="relative">
                  <img className="absolute w-20 team-hat" src={hat4} alt="" />
                  <img className="border-2 border-white rounded-xl" src={mon4} alt="" />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <img src={twitterIcon} className="w-5 mr-2" />
                  <span className="block font-heading text-2xl leading-none text-center">NICO</span>
                </div>
                <span className="text-md lg:text-lg leading-none">
                  {' '}
                  {t('Team.member4.title', 'Design Ninja')}
                </span>
              </a>
            </div>
          </div>

          <div className="flex flex-col items-center md:space-x-8 md:flex-row md:justify-between md:justify-between md:items-baseline text-xl text-center">
            <div className="flex flex-row space-x-8 mb-8">
              <a
                href="https://twitter.com/CodingEres"
                target="_blank"
                className="md:w-1/2 mt-4 hover:animate-bounceOnce cursor-pointer"
              >
                <div className="relative">
                  <img className="absolute w-20 team-hat" src={hat5} alt="" />
                  <img className="border-2 border-white rounded-xl" src={mon5} alt="" />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <img src={twitterIcon} className="w-5 mr-2" />
                  <span className="block font-heading text-2xl leading-none">ERES</span>
                </div>
                <span className="text-md lg:text-lg leading-none">
                  {' '}
                  {t('Team.member5.title', 'Code Jammer')}
                </span>
              </a>
              <a
                href="https://twitter.com/UncleNilskij"
                target="_blank"
                className="md:w-1/2 mt-4 hover:animate-bounceOnce cursor-pointer"
              >
                <div className="relative">
                  <img className="absolute w-20 team-hat" src={hat6} alt="" />
                  <img className="border-2 border-white rounded-xl" src={mon6} alt="" />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <img src={twitterIcon} className="w-5 mr-2" />
                  <span className="block font-heading text-2xl leading-none">NILS</span>
                </div>
                <span className="text-md lg:text-lg leading-none">
                  {' '}
                  {t('Team.member6.title', 'Whip Cracker')}
                </span>
              </a>
            {/* </div>
            <div className="flex flex-row space-x-8 mb-8"> */}
              <a
                href="https://twitter.com/mj_has_arrived"
                target="_blank"
                className="md:w-1/2 mt-4 hover:animate-bounceOnce cursor-pointer"
              >
                <div className="relative">
                  <img className="absolute w-20 team-hat" src={hat7} alt="" />
                  <img className="border-2 border-white rounded-xl" src={mon7} alt="" />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <img src={twitterIcon} className="w-5 mr-2" />
                  <span className="block font-heading text-2xl leading-none text-center">MJ</span>
                </div>
                <span className="text-md lg:text-lg leading-none">
                  {' '}
                  {t('Team.member7.title', 'Camera Man')}
                </span>
              </a>
              {/* <a
                href="https://twitter.com/Charliedoodle57"
                target="_blank"
                className="md:w-1/2 mt-4 hover:animate-bounceOnce cursor-pointer"
              >
                <div className="relative">
                  <img className="absolute w-20 team-hat" src={hat8} alt="" />
                  <img className="border-2 border-white rounded-xl" src={mon8} alt="" />
                </div>
                <div className="flex justify-center items-center mt-4">
                  <img src={twitterIcon} className="w-5 mr-2" />
                  <span className="block font-heading text-2xl leading-none text-center">
                    CHARLES
                  </span>
                </div>
                <span className="text-md lg:text-lg leading-none">
                  {' '}
                  {t('Team.member8.title', 'Fun Guy')}
                </span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-16">
        <img className="border-2 border-white rounded-xl" src={rarity} alt="Monsta Party Rarity" />
      </div> */}
    </section>
  );
};

export default Team;
